import { createStore } from "vuex";
import router from "./router/router";
import { http } from '@/services/config';

const store = createStore({
    state: {
        user: {},
        type: "",
        isActive: false,
        isLoading: null,
        pinned: false,
        toggled: null,
        tokenSubmit: {
            scope: "",
            grant_type: "refresh_token",
            refresh_token: null,
            client_id: process.env.VUE_APP_SECURITY_CLIENT_ID,
            client_secret: process.env.VUE_APP_SECURITY_CLIENT_SECRET,
        }
    },

    mutations: {
        changeLoading(state, boolean) {
            state.isLoading = boolean;
        },

        pinSidebar(state) {
            state.pinned = !state.pinned;
        },

        toggleSidebar(state) {
            state.toggled = state.toggled == null ? true : !state.toggled;
        },
    },

    actions: {
        actionCheckToken(){
            let token = localStorage.getItem('token');
            if (!token) {
                return router.push({name: 'Unauthorized'})
            } else {
                setBearerToken(token);
            }
        },

        actionLogin(payload) {
            console.log('actionLogin')
            console.log(payload)
            localStorage.setItem('access_token', payload.access_token);
            localStorage.setItem('refresh_token', payload.refresh_token);
            localStorage.setItem('database', payload.database);

        },
    }
});

const setBearerToken = token => {
    http.defaults.headers.authorization = `Bearer ${token}`;
}

export default store;

