import Auth from '@/services/auth';
import {http} from "../services/config";

export default async(to, from, next) => {
    console.log('beforeLogin')
    try {
        if (to.name === 'Login') {
            const tokenInitial = to.params.token

            await Auth.getAuthorization(tokenInitial).then((resp) => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                localStorage.removeItem('company_id')

                localStorage.setItem('access_token', resp.data.access_token);
                localStorage.setItem('refresh_token', resp.data.refresh_token);
                localStorage.setItem('company_id', resp.data.database);
                localStorage.setItem('user_name', resp.data.userName);
                localStorage.setItem('company_name', resp.data.companyName);
                localStorage.setItem('description_company', resp.data.description)

            }).catch(() => {
                next({name: 'Unauthorized'})
            });

            next({name: 'Home'});
        } else if (to.name === 'Unauthorized') {
            next();
        } else {
            http.defaults.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token');
            http.defaults.headers.common['company-id'] = localStorage.getItem('company_id');
            http.defaults.headers.common['refresh-token'] = localStorage.getItem('refresh_token');

            let promise = await Auth.checkToken().then((resp) => {
                return resp.data;
            }).catch((err) => {
                return err;
            })

            let result = await Promise.resolve(promise).then((re) => {
                return re;
            }).catch((er) => {
                return er;
            })

            if (result.code === 202) {
                localStorage.setItem('access_token', result.access_token)
                localStorage.setItem('refresh_token', result.refresh_token)
                next()
            } else {
                next();
            }
        }
    } catch (err) {
        next({name: 'Unauthorized'});
    }
}




