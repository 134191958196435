{
  "DOCUMENTS": {
    "DOCUMENTS": "DOCUMENTOS",
    "PHONE": "Telefone",
    "NAME": "Nome",
    "CANCEL": "Cancelar Documento",
    "STATS_DOCUMENT": "Status do Documento",
    "SIGNATURE_STATS": "Status da Assinatura",
    "IN_PROGRESS": "Em Progresso",
    "THIS_MOMENT": "Neste Momento",
    "CANCELEDS": "Cancelados",
    "FINISHEDS": "Assinados",
    "SIGNERS": "SIGNATÁRIOS",
    "DEADLINE": "Expiração",
    "ALL": "Ver Todos",
    "VIEW_DOC_ORIGINAL": "Ver Original",
    "VIEW_DOC_ALREADY_SIGN": "Ver Assinado",
    "MSG": {
      "CLICK_TO_VIEW_ALL_SIGNERS": "Clique em visualizar para ver todos os signatários",
      "NONE_DOCUMENT_FOUND": "Nenhum documento encontrado!",
      "ARE_SURE_WANT_TO_DELETE_THIS_DOCUMENT": "Tem certeza que deseja excluir este documento?",
      "ARE_SURE_WANT_TO_SEND_WHATSAPP_MESSAGE": "Tem certeza que deseja enviar solicitação de assinatura via whatsapp para esse usuário?"
    },
    "MODAL_TITLE": {
      "DOCUMENT": "Documento"
    },
    "MENU": {
      "WAITING": "Aguardando",
      "HOME": "Home",
      "GENERAL": "Geral",
      "FINISHED": "Assinado",
      "SIGNEDS": "Assinados",
      "CANCELED": "Cancelados",
      "ALL": "Todos os Documentos"
    },
    "MESSAGES": {
      "SUCCESS_CANCEL_DOCUMENT": "Documento cancelado com sucesso!",
      "FAIL_CANCEL_DOCUMENT": "Documento não deletado!"
    }
  },
  "APP": {
    "PROFILE": "Perfil",
    "SINGOUT": "Sair",
    "CHANGE_PASSWORD": "Alterar Senha"
  }
}
