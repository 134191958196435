import {createWebHistory, createRouter} from "vue-router";
import beforeEach from "./beforeEach";

const routes = [
    {
        path: "/",
        name: "App",
        component: () => import(/* webpackChunkName: "LayoutApp" */ '@/layouts/App'),
        children: [
            {
                path: "/",
                name: "Home",
                component: () => import(/* webpackChunkName: "Home" */ '@/pages/home/homeComponent'),
        },
            {
                path: "/login/:token",
                name: "Login",
                component: () => import(/* webpackChunkName: "Home" */ '@/pages/home/loginComponent'),
        },
            {
                path: "/all",
                name: "All",
                component: () => import(/* webpackChunkName: "All" */ '@/pages/documents/indexComponent'),
        },
            {
                path: "/running",
                name: "Running",
                component: () => import(/* webpackChunkName: "Running" */ '@/pages/documents/runningIndexComponent'),
        },
            {
                path: "/canceled",
                name: "Canceled",
                component: () => import(/* webpackChunkName: "Canceled" */ '@/pages/documents/canceledIndexComponent'),
        },
            {
                path: "/signed",
                name: "Signed",
                component: () => import(/* webpackChunkName: "Finished" */ '@/pages/documents/signedIndexComponent'),
        }
        ],
    },
    {
        path: "/unauthorized",
        name: "Unauthorized",
        component: () => import(/* webpackChunkName: "Unauthorized" */ '@/pages/home/UnauthorizedIndexComponent'),
    }
    ];

    const router = createRouter({
        history: createWebHistory(),
        routes,
    });

    router.beforeEach(beforeEach);

    export default router;
